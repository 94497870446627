import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import { TickerTape } from "react-ts-tradingview-widgets";

  const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting'}).format(amount);

const Invest = () => {

    let theme = localStorage.getItem('RRW MINING-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
    const [currentTheme, setCurrentTheme] = useState(theme);

    const initialPlans = {
        loading: true,
        planList: null
    }
    const [plans, setPlans] = useState(initialPlans);

    const fetchPlans = async() => {
            let plan = localStorage.getItem('plans');
            if (plan != null) {
                plan = JSON.parse(plan);
                setPlans({...plans, loading: false, planList: plan}); 
            }
               
      }
      const setCurrentPlan = (plan) => {
        localStorage.setItem('newPlan', JSON.stringify(plan));
        const planEvent = new Event('newPlan');
        window.dispatchEvent(planEvent);
      }

      useEffect(()=>{
        fetchPlans();

        window.addEventListener('themeChanged', function (e) {
             let theme = localStorage.getItem('RRW MINING-Theme');
             if (theme !== null) {
                 if (theme === 'dark-mode') {
                    setCurrentTheme('dark')
                 }else{
                    setCurrentTheme('light') 
                 }
                
             }             
        }, false);

      }, [])
      const {loading, planList} = plans;
  return (
    <div id="page-content">
        <TickerTape colorTheme={currentTheme}></TickerTape>
        {loading? <div>
            Loading...
        </div>:
        <div>
        <div className="card card-style bg-fade-blue border border-fade-blue alert alert-dismissible show fade p-0 mb-3">
           <div className="content my-3">
               <div className="d-flex">
                   <div className="align-self-center no-click">
                       <i className="bi bi-info-square font-33 color-blue-dark d-block"></i>
                   </div>
                   <div className="align-self-center no-click">
                       <p className="color-theme mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                           <strong>Available Investment Plans</strong> - Kindly select any investment plans below to get started
                       </p>
                   </div>
               </div>
           </div>
       </div>
        <div className="card card-style px-0">
        <div className="content mt-0 mb-0">        
            {planList.map(plan => (
                <Link key={plan.id} onClick={()=>setCurrentPlan(plan)} data-bs-toggle="offcanvas" data-bs-target="#menu-investment" to="#" className="d-flex pb-3 border-bottom">
                    <div className="align-self-center">
                        <span className="icon icon-xxl gradient-yellow me-2 shadow-bg shadow-bg-s rounded-s">
                            <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1651982670/investment-2955629-2453979.png" width="45" className="rounded-s" alt="img"/>
                        </span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h4 className="pt-1 mb-n1 color-theme">{plan.name}</h4>
                        <p className="mb-0 font-12 ">{plan.description}</p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                        <h4 className="pt-1 mb-n1 color-red-dark">
                         <span className='color-theme'>From</span> {getAmount(plan.min_amount)}
                        </h4>
                        <h4 className="pt-1 mb-n1 color-green-light">
                        <span className='color-theme'>To</span> { parseFloat(plan.max_amount) >= 1000000 ? 'Unlimited' : getAmount(plan.max_amount)}
                        </h4>
                    </div>
                </Link> 
            ))}
                       
        </div>
    </div>
    </div>
    }
    </div>
  )
}

export default Invest